import * as chakra from '@chakra-ui/core'
import { navigate } from 'gatsby'
import { rem } from 'design'
import { SmallButtonPrimary } from 'design/components/Button/Button'
import { IconCheck } from '@butcherbox/freezer'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { queryCache } from 'react-query'
import { SubscriptionContextRemote } from '~/context/subscriptionRemote'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import * as simulations from './simulations'
import { Subscription } from '~/bb-api/schemata'
import {
  ControlWrapper,
  DebouncedSearch,
} from '~/components/DevExperimentController/DevExperimentController'

export default function SimulationLauncher() {
  const user = React.useContext(UserContext)
  const { subscription } = React.useContext(SubscriptionContextRemote) || {}
  const showToast = React.useContext(ToastContext)
  const [open, setOpen] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState('')

  const [appliedSimulationMap, updateAppliedSimulationMap] = React.useState(
    new Map<simulations.Simulation, { config?: number; interceptor?: number }>()
  )

  const createClickHandler = (simulation: simulations.Simulation) => () => {
    const { config, interceptor } = appliedSimulationMap.get(simulation) || {}

    if (appliedSimulationMap.has(simulation)) {
      !!config && axios.interceptors.request.eject(config)
      !!interceptor && axios.interceptors.response.eject(interceptor)

      appliedSimulationMap.delete(simulation)
      updateAppliedSimulationMap(new Map(appliedSimulationMap))

      showToast('success', {
        children: `Simulation "${simulation.name}" has been disabled`,
      })
    } else {
      let userValue: string
      if (simulation.userPrompt) {
        userValue = window.prompt(simulation.userPrompt)
      }

      appliedSimulationMap.set(simulation, {
        config: simulation?.config
          ? axios.interceptors.request.use(
              simulation?.config(user, subscription)
            )
          : undefined,
        interceptor:
          simulation.responseInterceptor || simulation.errorInterceptor
            ? axios.interceptors.response.use(
                simulation?.responseInterceptor
                  ? simulation?.responseInterceptor(
                      user,
                      subscription as Subscription,
                      userValue
                    )
                  : undefined,
                simulation?.errorInterceptor
                  ? simulation?.errorInterceptor(
                      user,
                      subscription as Subscription
                    )
                  : undefined
              )
            : undefined,
      })

      updateAppliedSimulationMap(new Map(appliedSimulationMap))

      showToast('success', {
        children: `Simulation "${
          simulation?.name
        }" has been enabled until you refresh the page.${
          simulation?.feedback ? ' ' + simulation?.feedback : ''
        }`,
      })

      if (simulation?.redirectTo) {
        navigate(simulation?.redirectTo, { state: simulation?.navigateState })
      }
    }

    simulation?.cacheKeys.forEach((key) => queryCache.invalidateQueries(key))
  }

  return (
    <>
      <SmallButtonPrimary
        aria-hidden
        bg="bb.pesto"
        color="white"
        h={rem(24)}
        onClick={() => setOpen(true)}
        title="View available simulations"
      >
        Simulate
      </SmallButtonPrimary>

      <chakra.Drawer
        isOpen={open}
        onClose={() => setOpen(false)}
        placement="left"
      >
        <chakra.DrawerOverlay />
        <chakra.DrawerContent>
          <chakra.DrawerHeader>Apply Simulation</chakra.DrawerHeader>

          <chakra.Stack
            as={chakra.DrawerBody}
            direction="column"
            overflowY="auto"
            pb={rem(60)}
            spacing={rem(10)}
          >
            <ControlWrapper pos="relative">
              <DebouncedSearch onChange={setSearchTerm} />
            </ControlWrapper>

            {Object.values(simulations)
              .filter(
                ({ name }) =>
                  !searchTerm || name.match(new RegExp(searchTerm, 'i'))
              )
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((simulation, index) => (
                <chakra.PseudoBox
                  _hover={{ borderColor: 'bb.slate' }}
                  border="1px solid"
                  borderColor={
                    appliedSimulationMap.has(simulation)
                      ? 'currentcolor'
                      : 'bb.silt'
                  }
                  borderRadius="3px"
                  color={
                    appliedSimulationMap.has(simulation)
                      ? 'bb.spruce'
                      : 'bb.slate'
                  }
                  cursor="pointer"
                  d="flex"
                  flexShrink={0}
                  key={index}
                  onClick={createClickHandler(simulation)}
                  px={rem(8)}
                  py={rem(6)}
                >
                  {appliedSimulationMap.has(simulation) && (
                    <IconCheck
                      customColor={{ base: 'pesto' }}
                      marginRight={8}
                      size="text"
                    />
                  )}{' '}
                  {simulation.name}
                </chakra.PseudoBox>
              ))}
          </chakra.Stack>
        </chakra.DrawerContent>
      </chakra.Drawer>
    </>
  )
}
