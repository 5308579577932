export const groupedItem = {
  amount: 5200,
  creditAmount: 0,
  description: 'Lobster Bundle',
  fullDescription: '',
  image:
    'https://www.butcherbox.com/wp-content/uploads/2015/07/Cold-cracked-Tails.jpg',
  meatGroup: 'seafood',
  packSize: '',
  price: 5200,
  quantity: 1,
  sku: '457646',
  groupedItems: [
    {
      amount: 0,
      description: 'Cold Cracked Lobster Meat',
      creditAmount: 0,
      fullDescription: 'ButcherBox Cold Cracked Lobster Meat',
      image:
        'https://www.butcherbox.com/wp-content/uploads/2015/07/Cold-cracked-Tails.jpg',
      meatGroup: 'seafood',
      packSize: '8 oz pack',
      price: 5200,
      quantity: 1,
      sku: '971893',
    },
  ],
}
