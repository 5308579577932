import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import set from 'clean-set'
import dayjs from 'dayjs'
import { RequireAtLeastOne } from 'design/helpers'
import { sampleSize } from 'lodash'
import * as constants from '~/bb-api/constants'
import * as endpoints from '~/bb-api/endpoints'
import { GET_UPCOMING_ORDER } from '~/bb-api/endpoints'
import * as schemata from '~/bb-api/schemata'
import { ICongratulationsState } from '~/routes/CheckoutFlow/PaymentFrame/types'
import { groupedItem } from './constants'
import { BadgeType } from '~/utils/makeCardBadgeProps'
import { getTagInfoForSku, JustCookSkuAndTags } from '~/data/justcook-tags-data'

const getSubscriptions = endpoints.SUBSCRIPTIONS
const getLatestShipment = endpoints.GET_LATEST_SHIPMENT()
const getUpcomingOrder = endpoints.GET_UPCOMING_ORDER
const getCustomerStats = endpoints.GET_CUSTOMER_STATS

export type Simulation = RequireAtLeastOne<
  {
    cacheKeys: string[]
    /** provide extra info to be displayed in the toast message if desired */
    feedback?: string
    redirectTo?: string
    name: string
    config?: (
      user: schemata.User,
      subscription: schemata.Subscription | undefined
    ) => (
      config: AxiosRequestConfig
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>
    errorInterceptor?: (
      user: schemata.User,
      subscription: schemata.Subscription
    ) => (e: AxiosError) => any
    userPrompt?: string
    responseInterceptor?: (
      user: schemata.User,
      subscription: schemata.Subscription,
      userValue: string
    ) => (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>
    navigateState?: Object
  },
  'config' | 'errorInterceptor' | 'responseInterceptor' | 'navigateState'
>

/**
 * Define axios request config transformers and/or response interceptors to modify
 * a react-query's data to suit the needs of a simulation (dev-applied local state for testing.)
 *
 * The `cacheKeys` subfield will instruct the simulation manager on which
 * cache entries to refetch, resulting in the interceptor being applied
 * and the desired result appearing locally in the UI.
 *
 * Config changes & interceptors are persistent until you reload the page or unapply the
 * simulation via the simulation launcher.
 *
 * NOTE: Interceptors _do not_ catch errors (including 404). If you want to force an API call that
 * you know can 404 to return content, add a config transformer that modifies the `validateStatus`
 * config option such that 404 does not trigger an error state.
 */

export const simulateNewMemberStats: Simulation = {
  cacheKeys: [
    constants.CACHE_KEY_CUSTOMER_STATS,
    constants.CACHE_KEY_LATEST_SHIPMENT,
  ],
  name:
    'Customer Stats: Simulate new member with first box (orderStats.orderCount = 1)',
  responseInterceptor: (user) => (response: AxiosResponse) => {
    if (response.config.url === getCustomerStats(user.id)) {
      response.data.orderCount = 1
    }
    return response
  },
}

export const addPreparedProteinTypeToStatApi: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CUSTOMER_STATS],
  name: 'Customer Stats: Add "prepared" as most ordered protein type',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.CustomerStats>
  ) => {
    if (response.config.url === getCustomerStats(user.id)) {
      response.data.proteinTypes = [
        { type: 'prepared', count: 89 },
        { type: 'pork', count: 74 },
        { type: 'chicken', count: 29 },
        { type: 'seafood', count: 3 },
      ]
    }
    return response
  },
}

export const addNoJustCookRecipesSkusToRecentOrder: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name:
    'Recent Order: Add Skus with no JustCook Recipes and redirect to Whats for dinner',
  redirectTo: '/account/whats-for-dinner',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const [order] = response.data.data

      order.box.items.push(
        {
          amount: 0,
          creditAmount: 0,
          description: 'Apple Gouda Sausage',
          fullDescription: 'Apple Gouda Sausage',
          image:
            'https://www.butcherbox.com/apiv2/files/products/NIman_Apple_Gouda_Sausage.jpg',
          meatGroup: 'pork',
          packSize: '3lbs',
          price: 0,
          quantity: 2,
          // sku has no recipe and is included in justcook tags data
          sku: '971856',
        },
        {
          amount: 0,
          creditAmount: 0,
          description: 'Charcuterie',
          fullDescription: 'Prosciutto-Gen. Sal.-Capocollo Variety',
          image:
            'https://www.butcherbox.com/apiv2/files/products/charcuterie-mobile-bg_11.jpeg',
          meatGroup: 'prepared',
          packSize: '1 x 6 oz',
          price: 0,
          quantity: 1,
          // sku has no recipe and is not included in justcook tags data
          sku: '971749',
        }
      )
    }

    return response
  },
}

export const addNoDogTreatsSkusToRecentOrder: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name: 'Recent Order: Add Dog Treat Skus to Whats for dinner',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const order = response.data.data
      const orderNumberToRender = order[0]?.box?.items?.length !== 0 ? 0 : 1
      order[orderNumberToRender].box.items.push(
        {
          amount: 0,
          creditAmount: 0,
          description: 'Beef & Sweet Potato Jerky Dog Treats',
          fullDescription: 'Beef & Sweet Potato Jerky Dog Treats',
          image:
            'https://www.butcherbox.com/apiv2/files/products/Memberdeal_BBGood_BeefSP_R2.jpg',
          meatGroup: 'dry',
          packSize: '0.5lbs',
          price: 0,
          quantity: 2,
          //sku doesn't exist on skuExclusion.ts file and it is dry meat group
          sku: '971999',
        },
        {
          amount: 0,
          creditAmount: 0,
          description: 'Beef & Sweet Potato Jerky Dog Treats',
          fullDescription: 'Beef & Sweet Potato Jerky Dog Treats',
          image:
            'https://www.butcherbox.com/apiv2/files/products/Memberdeal_BBGood_BeefSP_R2.jpg',
          meatGroup: 'pet_food',
          packSize: '0.5lbs',
          price: 0,
          quantity: 2,
          //sku doesn't exist on skuExclusion.ts file and it is dry meat group
          sku: '971999',
        }
      )
    }
    return response
  },
}

export const emptyBoxItemsFirstOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS],
  name:
    'First Order: Mimic processing (empty box items, has invoice items and addons)',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === endpoints.GET_ORDERS(user.id)) {
      const orders = response.data.data
      const firstOrderIndex = orders.length - 1
      orders[firstOrderIndex].trackingInformation.status = 'processing'
      orders[firstOrderIndex].box.items = []
      orders[firstOrderIndex].box.type = 'cst'
      orders[firstOrderIndex].box.addons = [groupedItem]
      orders[firstOrderIndex].invoiceItems = [groupedItem]
    }
    return response
  },
}

export const emptyFirstOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS],
  name: 'First Order: Empty box items, has no invoice items and addons',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === endpoints.GET_ORDERS(user.id)) {
      const orders = response.data.data
      const firstOrderIndex = orders.length - 1
      orders[firstOrderIndex].trackingInformation.status = 'processing'
      orders[firstOrderIndex].box.items = []
      orders[firstOrderIndex].box.type = 'beef_and_pork'
      orders[firstOrderIndex].box.addons = []
      orders[firstOrderIndex].invoiceItems = []
      orders[firstOrderIndex].extras = []
      orders[firstOrderIndex].box.offers = []
    }
    return response
  },
}

export const emptyBoxItmesInSubscription: Simulation = {
  cacheKeys: [constants.CACHE_KEY_SUBSCRIPTION],
  name: 'Subscription: Empty box items',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Subscription[] }>
  ) => {
    if (response.config.url === getSubscriptions(user.id)) {
      response.data.data[0].box.items = []
    }
    return response
  },
}

export const inTransitFirstOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS],
  name: 'First Order: Mark as in transit',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === endpoints.GET_ORDERS(user.id)) {
      const orders = response.data.data
      orders[orders.length - 1].trackingInformation.status = 'in_transit'
    }
    return response
  },
}

export const throwErrorForSMSFormSubmitted: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CUSTOMER],
  name: 'SMS: Throw Unsubscribed Error',
  errorInterceptor: (user) => (
    response: AxiosError & { data: { errors: string }; status: number }
  ) => {
    if (response.config.url === `${endpoints.UPDATE_SMS_CONSENT(user.id)}`) {
      response.data.errors =
        'You have unsubscribed. To resubscribe, please text fakeResubscribeNumber with START, YES, or UNSTOP.'
      response.status = 400
    }
    return Promise.reject(response)
  },
}

export const addEmptyBoxToFirstRecentOrder: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name: 'Recent Order: Adding empty box items to first recent order',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const orders = response.data.data
      orders[0].box.items = []
      orders[1].trackingInformation.shipmentDate = '2022-10-21'
      orders[1].box.items = [
        {
          amount: 0,
          creditAmount: 1300,
          description: 'Burgers',
          fullDescription:
            'Our burgers are individually packed with a ratio of 80% meat to 20% fat&mdash;the perfect centerpiece of your next cookout.',
          image:
            'https://www.butcherbox.com/apiv2/files/products/IQF_burgers_1',
          meatGroup: 'beef',
          packSize: '6 x 5.3 oz burgers',
          price: 0,
          quantity: 1,
          sku: '971313',
        },
        {
          amount: 0,
          creditAmount: 1800,
          description: 'Top Sirloin Steaks',
          fullDescription:
            'Highly versatile with rich flavor, top sirloin works well on the grill or seared in a pan.',
          image:
            'https://www.butcherbox.com/apiv2/files/products/Top-Sirloin-1.jpg',
          meatGroup: 'beef',
          packSize: '4 x 6 oz steaks',
          price: 0,
          quantity: 1,
          sku: '971323',
        },
        {
          amount: 0,
          creditAmount: 2200,
          description: 'NY Strip Steaks',
          fullDescription:
            'Ex mely tender and well marbled, the NY strip is prized at steakhouses. Sear to let its flavor shine.',
          image:
            'https://www.butcherbox.com/apiv2/files/products/NY-Strip_new1.jpg',
          meatGroup: 'beef',
          packSize: '2 x 10 oz steaks',
          price: 0,
          quantity: 1,
          sku: '971439',
        },
        {
          amount: 0,
          creditAmount: 1900,
          description: 'Flat Iron Steaks',
          fullDescription:
            "The flat iron is a quick-cooking steak with a deep, meaty flavor. Lean and gorgeously shaped, it's perfect for pan-searing.",
          image:
            'https://www.butcherbox.com/apiv2/files/products/FlatIron_latest.jpg',
          meatGroup: 'beef',
          packSize: '4 x 6 oz steaks',
          price: 0,
          quantity: 1,
          sku: '971335',
        },
      ]
    }
    return response
  },
}

export const makeRecentOrderReportable: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}`],
  name: 'Recent Order: Mark reportable',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}`) {
      const orders = response.data.data
      orders[0].reportable = true
    }
    return response
  },
}

export const addEmptyBoxItemsToRecentOrder: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name:
    'Recent Order: Adding empty box items to order with only one recent ordered box',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const orders = response.data.data
      orders[0].box.items = []
      orders.splice(1, 1)
    }
    return response
  },
}

export const adding3PackBaconToOrder: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name: 'Recent Order: Adding 3 - pack bacon',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const orders = response.data.data
      orders[0].box.offers.push({
        amount: 0,
        creditAmount: 800,
        description: 'Bacon 3 pack ',
        fullDescription:
          "Meet the best bacon on the planet. Uncured, nitrate free, and sugar free, it's great baked or fried.",
        expirationDate: null,
        status: 'active',
        isPausable: true,
        groupedItems: [
          {
            amount: 0,
            creditAmount: 800,
            description: 'Bacon 3 pack ',
            fullDescription:
              "Meet the best bacon on the planet. Uncured, nitrate free, and sugar free, it's great baked or fried.",
            image:
              'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
            meatGroup: 'pork',
            packSize: '1 X 3 10 oz pack',
            price: 0,
            quantity: 1,
            sku: '974022',
          },
        ],
        image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
        meatGroup: 'pork',
        offerGroup: null,
        packSize: '1 X 3 10 oz pack',
        price: 0,
        quantity: 1,
        sku: '974022',
      })
    }

    return response
  },
}

export const changeMostRecentOrderToBasicBox: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=3`],
  name: 'Recent Order: Change box type to basic $99 box',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=3`) {
      const [order] = response.data.data

      order.box.type = 'basic_beef_chicken_pork'
      order.box.name = 'Basic Beef Chicken and Pork'
    }

    return response
  },
}

export const changeMostRecentOrderToAllBeef: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=3`],
  name: 'Recent Order: Change box type to All Beef',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=3`) {
      const [order] = response.data.data

      order.box.type = 'all_beef'
      order.box.name = 'All Beef'
    }

    return response
  },
}

export const maskChangeBoxTypeBasicBox: Simulation = {
  cacheKeys: [
    constants.CACHE_KEY_SUBSCRIPTION,
    constants.CACHE_KEY_UPCOMING_ORDER,
  ],

  name: 'Subscription: Simulate basic $99 box',

  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<
      | {
          data?: schemata.Subscription[]
        }
      | schemata.UpcomingOrder
    >
  ) => {
    if (
      'data' in response.data &&
      response.config.url === getSubscriptions(user.id)
    ) {
      // type narrowing
      response.data.data[0].box.type = 'basic_beef_chicken_pork'
      response.data.data[0].box.name = 'Basic Beef Chicken and Pork'
    }

    if (isUpcomingOrderResponse(response, user, subscription)) {
      response.data.subscription.box.type = 'basic_beef_chicken_pork'
      response.data.subscription.box.name = 'Basic Beef Chicken and Pork'
    }

    return response
  },
}

function isUpcomingOrderResponse(
  response: AxiosResponse<any>,
  user: schemata.User,
  subscription: schemata.Subscription
): response is AxiosResponse<schemata.UpcomingOrder> {
  return response.config.url === getUpcomingOrder(user.id, subscription.id)
}

export const markMostRecentOrderNotReportable: Simulation = {
  cacheKeys: [constants.CACHE_KEY_LATEST_SHIPMENT],
  name: 'Latest Shipment: Mark Not Reportable',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.Shipment>
  ) => {
    if (response.config.url === getLatestShipment(user.id, subscription.id)) {
      response.data.reportable = false
    }

    return response
  },
}

export const setCustomerStatsPuchasedSKusEmpty: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CUSTOMER_STATS],
  name: 'Customer Stats: Set PuchasedSkus To Empty',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.CustomerStats>
  ) => {
    if (response.config.url === getCustomerStats(user.id)) {
      response.data.purchasedSkus = []
    }

    return response
  },
}

export const markMostRecentOrderReportable: Simulation = {
  cacheKeys: [constants.CACHE_KEY_LATEST_SHIPMENT],
  name: 'Latest Shipment: Mark Reportable',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.Shipment>
  ) => {
    if (response.config.url === getLatestShipment(user.id, subscription.id)) {
      response.data.reportable = true
    }

    return response
  },
}

export const noFeaturedDeal: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_FEATURED_DEAL],
  name: 'Featured Deal: Hide',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.FeaturedDeal | null>
  ) => {
    if (response.config.url === endpoints.GET_FEATURED_DEAL(user.id)) {
      response.status = 404
      response.data = null
    }

    return response
  },
}

export const showFeaturedDealProduct: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_FEATURED_DEAL],
  name: 'Featured Deal: Show Deal',
  config: (user) => (config: AxiosRequestConfig) => {
    if (config.url === endpoints.GET_FEATURED_DEAL(user.id)) {
      config.validateStatus = (status) =>
        // featured-deal returns 404 if nothing is set, so modify the config to not reject this as an error
        (status >= 200 && status < 400) || status === 404
    }

    return config
  },
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.FeaturedDeal>
  ) => {
    if (response.config.url === endpoints.GET_FEATURED_DEAL(user.id)) {
      response.status = 200
      response.data = {
        //@ts-ignore
        deal: {
          description: 'Salmon and Sirloin',
          fullDescription: '2lbs of Wild Salmon & 4 x 6oz Sirloin Steaks',
          image:
            'https://www.butcherbox.com/apiv2/files/products/salmon_sirloin_3_2_add-ons_monthly_specials.jpg',
          packSize: '2lbs. of Salmon and 4 x 6 oz. steaks',
          isUnavailable: false,
          price: 3900,
          sku: '456151',
        },
        heroTitle: 'Salmon!',
        heroDescription: 'Get delicious Sockeye Salmon delivered to your door!',
        heroImage:
          'https://www.butcherbox.com//wp-content/uploads/2019/10/hero_slide_v2.jpg',
      }
    }
    return response
  },
}

export const showFeaturedDealLink: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_FEATURED_DEAL],
  name: 'Featured Deal: Show Link',
  config: (user) => (config: AxiosRequestConfig) => {
    if (config.url === endpoints.GET_FEATURED_DEAL(user.id)) {
      config.validateStatus = (status) =>
        // featured-deal returns 404 if nothing is set, so modify the config to not reject this as an error
        (status >= 200 && status < 400) || status === 404
    }

    return config
  },
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.FeaturedDeal>
  ) => {
    if (response.config.url === endpoints.GET_FEATURED_DEAL(user.id)) {
      response.status = 200
      response.data = {
        heroLink: '/account/deals',
        heroTitle: 'Salmon!',
        heroDescription: 'Get delicious Sockeye Salmon delivered to your door!',
        heroImage:
          'https://www.butcherbox.com//wp-content/uploads/2019/10/hero_slide_v2.jpg',
        heroButtonText: 'Click me',
      }
    }
    return response
  },
}

export const cancelledUser: Simulation = {
  cacheKeys: [
    constants.CACHE_KEY_UPCOMING_ORDER,
    constants.CACHE_KEY_SUBSCRIPTION,
  ],
  name: 'Subscription: Simulate Cancelled',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<
      | {
          data?: schemata.Subscription[]
          message?: string
        }
      | undefined
    >
  ) => {
    if (
      response.config.url === getSubscriptions(user.id) &&
      response?.data?.data?.[0].status
    ) {
      response.data.data[0].status = 'canceled'
    }

    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      response.data = undefined
      response.status = 404
      throw response
    }

    if (
      response.config.method === 'PUT' &&
      response.config.url ===
        endpoints.UPDATE_SUBSCRIPTION(user.id, subscription.id)
    ) {
      response.data = { message: 'Cannot update a canceled subscription' }
      response.status = 400
      throw response
    }

    return response
  },
}

export const showPausedAndUnpausedOffersInOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_SUBSCRIPTION],
  name: 'Subscription: Show a Paused and Unpaused Offer',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Subscription[] }>
  ) => {
    if (response.config.url === getSubscriptions(user.id)) {
      response.data.data[0].box.offers = [
        ...response.data.data[0].box.offers,
        {
          amount: 0,
          description: 'Bacon For Life',
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          isPausable: true,
          meatGroup: 'pork',
          packSize: '1 x 10 oz pack',
          price: 0,
          quantity: 1,
          sku: '971726',
          status: 'active',
          offerGroup: 'bacon_for_life',
          fullDescription: 'foo', // see: https://nbox-341.atlassian.net/browse/ENG-1053
          groupedItems: [
            {
              amount: 0,
              description: 'Bacon For Life',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
              meatGroup: 'pork',
              packSize: '1 x 10 oz pack',
              price: 0,
              quantity: 1,
              sku: '971726',
              fullDescription: 'foo', // see: https://nbox-341.atlassian.net/browse/ENG-1053
            },
          ],
        },
        {
          amount: 0,
          description: 'Ground Beef For Life',
          image:
            'https://www.butcherbox.com/apiv2/files/products/Ground-Beef_new1.jpg',
          isPausable: true,
          meatGroup: 'beef',
          packSize: '2 x 1 lb packs',
          price: 0,
          quantity: 1,
          sku: '971314',
          status: 'paused',
          offerGroup: 'ground_beef_for_life',
          fullDescription: 'foo', // see: https://nbox-341.atlassian.net/browse/ENG-1053
          groupedItems: [
            {
              amount: 0,
              description: 'Free Ground Beef For Life of Membership',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Ground-Beef_new1.jpg',

              meatGroup: 'beef',
              packSize: '2 x 1 lb packs',
              price: 0,
              quantity: 1,
              sku: '971314',
              fullDescription: 'foo', // see: https://nbox-341.atlassian.net/browse/ENG-1053
            },
          ],
        },
      ]
    }

    return response
  },
}

export const subscriptionPastDue: Simulation = {
  cacheKeys: [constants.CACHE_KEY_SUBSCRIPTION],
  name: 'Subscription: Simulate Past Due',
  responseInterceptor: (user) => (
    response: AxiosResponse<{
      data?: {
        data?: schemata.Subscription[]
      }
      message?: string
    }>
  ) => {
    if (
      response.config.url === getSubscriptions(user.id) &&
      response?.data?.data?.[0]?.status
    ) {
      response.data.data[0].status = 'past_due'
    }

    return response
  },
}

export const subscriptionErrorResponse: Simulation = {
  cacheKeys: [constants.CACHE_KEY_SUBSCRIPTION],
  name: 'Subscription: Error Response',
  responseInterceptor: (user) => (response: AxiosResponse<any>) => {
    if (response.config.url === getSubscriptions(user.id)) {
      throw new Error()
    }
    return response
  },
}

export const updateSubscriptionErrorResponse: Simulation = {
  cacheKeys: [constants.CACHE_KEY_SUBSCRIPTION],
  name: 'Update Subscription: Error response - periodEnd must be after today',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<any>
  ) => {
    if (
      response.config.url ===
      endpoints.UPDATE_SUBSCRIPTION(user.id, subscription.id)
    ) {
      response.data = {
        message: 'The periodEndDate must be a date after today.',
      }
      response.status = 400
      throw response
    }
    return response
  },
}

export const userActionErrorResponse: Simulation = {
  cacheKeys: [constants.CACHE_KEY_USER_ACTION],
  name: 'UserAction: Error Response',
  redirectTo: '/account/recipes?category=all',
  responseInterceptor: (user) => (response: AxiosResponse<any>) => {
    if (
      response.config.url ===
      `${endpoints.USER_ACTION(user.id)}?actionName=savedRecipes`
    ) {
      throw new Error()
    }
    return response
  },
}

export const ordersErrorResponse: Simulation = {
  cacheKeys: ['orders?limit=2'],
  name: 'Order: Error Response',
  responseInterceptor: (user) => (response: AxiosResponse<any>) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      throw new Error()
    }
    return response
  },
}

export const subscriptionPaymentProcessing: Simulation = {
  cacheKeys: [
    constants.CACHE_KEY_SUBSCRIPTION,
    constants.CACHE_KEY_UPCOMING_ORDER,
  ],
  name: 'Subscription: Simulate Payment Processing',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<
      | {
          data?: schemata.Subscription[]
          message?: string
        }
      | schemata.UpcomingOrder
    >
  ) => {
    if (response.config.url === getSubscriptions(user.id)) {
      const subscriptionData = response.data as {
        data: schemata.Subscription[]
      }
      if (subscriptionData?.data?.[0]?.status) {
        subscriptionData.data[0].status = 'payment_processing'
      }
    }

    if (response.config.url === GET_UPCOMING_ORDER(user.id, subscription.id)) {
      const orderData = response.data as schemata.UpcomingOrder
      orderData.subscription.status = 'payment_processing'
    }
    return response
  },
}

export const setBoxTypesUnavailable: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_TYPES],
  name: 'Box Types: Set All as Unavailable',
  responseInterceptor: () => (
    response: AxiosResponse<{ data: schemata.BoxTypeDefinition[] }>
  ) => {
    if (response.config.url === endpoints.GET_BOX_TYPES) {
      response.data.data.forEach((boxType) => {
        boxType.isDisabled = true
      })
    }

    return response
  },
}

export const setSomeItemsToUnavailableStatus: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_ITEMS],
  name: 'Box Items: Set Some as Unavailable',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.AvailableCustomCuts>
  ) => {
    if (
      response.config.url === endpoints.GET_BOX_ITEMS ||
      response.config.url === endpoints.GET_CUSTOMER_BOX_ITEMS(user.id)
    ) {
      Object.values(response.data).forEach((products) => {
        products.forEach((product) => {
          if (parseInt(product.sku) % 3) product.isUnavailable = true
        })
      })
    }

    return response
  },
}
export const setOtherItemsToUnavailableStatus: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_ITEMS],
  name: 'Box Items: Set Others as Unavailable',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.AvailableCustomCuts>
  ) => {
    if (
      response.config.url === endpoints.GET_BOX_ITEMS ||
      response.config.url === endpoints.GET_CUSTOMER_BOX_ITEMS(user.id)
    ) {
      Object.values(response.data).forEach((products) => {
        products.forEach((product) => {
          if (!(parseInt(product.sku) % 3)) product.isUnavailable = true
        })
      })
    }

    return response
  },
}

export const removeGroundBeefFromBoxItemsResponse: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_ITEMS],
  name: 'Custom Box Items: Ground Beef Does Not Exist',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.AvailableCustomCuts>
  ) => {
    if (
      response.config.url === endpoints.GET_BOX_ITEMS ||
      (user &&
        response.config.url === endpoints.GET_CUSTOMER_BOX_ITEMS(user.id))
    ) {
      for (const [category, products] of Object.entries(response.data)) {
        for (const product of products) {
          if (getTagInfoForSku(Number(product.sku))?.slug === 'ground-beef') {
            response.data = {
              ...response.data,
              [category]: [...products.filter((p) => p.sku !== product.sku)],
            }
          }
        }
      }
    }

    return response
  },
}

export const addMiscBoxItems: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_ITEMS],
  name: 'Box Items: Add Miscellaneous',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.AvailableCustomCuts>
  ) => {
    if (response.config.url === endpoints.GET_BOX_ITEMS) {
      response.data.miscellaneous = response.data.miscellaneous || []
      response.data.miscellaneous.push({
        description: 'Bacon Blast',
        fullDescription: "So much bacon you'll die, but happy and full of pork",
        image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
        isUnavailable: false,
        meatGroup: 'pork',
        packSize: '3lbs',
        price: 0,
        sku: '1234',
      })
    }

    return response
  },
}

export const addTriTipToAvailableBoxItems: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_ITEMS],
  name: 'Box Items: Make Tri-Tip Available',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.AvailableCustomCuts>
  ) => {
    if (
      response.config.url === endpoints.GET_BOX_ITEMS ||
      response.config.url === endpoints.GET_CUSTOMER_BOX_ITEMS(user.id)
    ) {
      response.data.beef = response.data.beef || []
      if (!response.data.beef.find((item) => item.sku === '971342')) {
        response.data.beef.push({
          description: 'Tri-Tip',
          fullDescription:
            "The tri-tip gets its name from its shape and is a favorite cut for grilling in California. It's traditionally prepared over a redwood fire, but your backyard BBQ is a great alternative.",
          image:
            'https://www.butcherbox.com/apiv2/files/products/Whole-Triptip_new.jpg',
          isUnavailable: false,
          meatGroup: 'beef',
          packSize: '1 x 1.5 lbs tri-tip',
          price: 0,
          sku: '971342',
        })
      }
    }
    return response
  },
}

export const removeTriTipFromAvailableBoxItems: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_ITEMS],
  name: 'Box Items: Make Tri-Tip un-available',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.AvailableCustomCuts>
  ) => {
    if (
      response.config.url === endpoints.GET_BOX_ITEMS ||
      response.config.url === endpoints.GET_CUSTOMER_BOX_ITEMS(user.id)
    ) {
      response.data.beef = response.data.beef || []
      response.data.beef = response.data.beef.filter(
        (item) => item.sku !== '971342'
      )
    }
    return response
  },
}

export const addGroundTurkeyToAvailableBoxItems: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_ITEMS],
  name: 'Box Items: Make Ground Turkey Available',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.AvailableCustomCuts>
  ) => {
    if (
      response.config.url === endpoints.GET_BOX_ITEMS ||
      response.config.url === endpoints.GET_CUSTOMER_BOX_ITEMS(user.id)
    ) {
      response.data.miscellaneous = response.data.miscellaneous || []
      if (!response.data.miscellaneous.find((item) => item.sku === '971731')) {
        response.data.miscellaneous.push({
          description: 'Ground Turkey',
          fullDescription:
            'Lean and tasty with no preservatives. Swap it in for your favorite ground beef recipes.',
          image:
            'https://www.butcherbox.com/apiv2/files/products/Ground_Turlkey_cut_sm.jpg',
          isUnavailable: false,
          meatGroup: 'turkey',
          packSize: '2 x 1 lb packs',
          price: 0,
          sku: '971731',
        })
      }
    }
    return response
  },
}

export const addGroupedItemsToMostRecentOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS],
  name: 'Recent Order: Add Member Deals',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === endpoints.GET_ORDERS(user.id)) {
      const [order] = response.data.data

      order.invoiceItems.push(
        {
          amount: 0,
          creditAmount: 0,
          description: 'Bacon Blast',
          fullDescription:
            "So much bacon you'll die, but happy and full of pork",
          groupedItems: [
            {
              amount: 0,
              creditAmount: 0,
              description: 'ButcherBox Bacon',
              fullDescription: '',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
              meatGroup: 'pork',
              packSize: '1 x 10 oz pack',
              price: 0,
              quantity: 2,
              sku: '971726',
            },
          ],
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          meatGroup: 'pork',
          packSize: '3lbs',
          price: 0,
          quantity: 1,
          sku: '1234',
        },
        {
          amount: 0,
          creditAmount: 0,
          description: 'Bacon Blast',
          fullDescription:
            "So much bacon you'll die, but happy and full of pork",
          groupedItems: [
            {
              amount: 0,
              creditAmount: 0,
              description: 'ButcherBox Bacon',
              fullDescription: '',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
              meatGroup: 'pork',
              packSize: '1 x 10 oz pack',
              price: 0,
              quantity: 2,
              sku: '971726',
            },
          ],
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          meatGroup: 'pork',
          packSize: '3lbs',
          price: 0,
          quantity: 1,
          sku: '1235',
        }
      )
    }

    return response
  },
}

export const addNonMeatItemToOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS],
  name: 'Recent Order: Add Tote - Redirect to Whats for Dinner',
  redirectTo: '/account/whats-for-dinner',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === endpoints.GET_ORDERS(user.id)) {
      const [order] = response.data.data

      order.invoiceItems.push({
        amount: 0,
        creditAmount: 0,
        description: 'Tote',
        fullDescription: 'wow a nice tote bag!',
        groupedItems: [],
        image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
        meatGroup: 'pork',
        packSize: '3lbs',
        price: 0,
        quantity: 1,
        sku: '971610',
      })
    }

    return response
  },
}

export const addExtrasToMostRecentOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS],
  name: 'Recent Order: Add Extras',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === endpoints.GET_ORDERS(user.id)) {
      const [order] = response.data.data

      order.extras.push({
        amount: 0,
        description: 'ButcherBox Bacon',
        fullDescription: '',
        image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
        meatGroup: 'pork',
        packSize: '1 x 10 oz pack',
        price: 1234,
        quantity: 2,
        sku: '971726',
      })
    }

    return response
  },
}

export const addExtrasToUpcomingOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Add Extras',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.UpcomingOrder>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      const upcomingOrder = response.data

      upcomingOrder.extras.push({
        amount: 0,
        description: 'ButcherBox Bacon',
        fullDescription: '',
        image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
        meatGroup: 'pork',
        packSize: '1 x 10 oz pack',
        price: 1234,
        quantity: 2,
        sku: '971726',
      })
    }

    return response
  },
}
export const addErrorUpcomingOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Error Response',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<any>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      throw new Error()
    }

    return response
  },
}

export const addInvoiceItemsStrikethroughToUpcomingOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Add Member Deals with Strikethrough',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.UpcomingOrder>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      response.data.invoiceItems.push(
        {
          amount: 0,
          description: 'Dino nuggets',
          fullDescription: 'Eat nuggets',
          groupedItems: [
            {
              amount: 0,
              description: 'CHICKEN NUGGETS',
              fullDescription: '',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
              meatGroup: 'pork',
              packSize: '1 x 10 oz pack',
              price: 1234,
              quantity: 2,
              sku: '971726',
            },
          ],
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          meatGroup: 'ticken',
          offerGroup: null,
          packSize: '3lbs',
          price: 1234,
          quantity: 1,
          sku: '1234',
          markupPrice: '13.00',
        },
        {
          amount: 0,
          description: 'Apple Gouda Sausage',
          fullDescription: 'Apple Gouda Sausage',
          image: 'https://www.butcherbox.com/apiv2/files/products/pork.svg',
          meatGroup: 'pork',
          offerGroup: null,
          packSize: '1 x 12 oz pack',
          price: 0,
          quantity: 1,
          sku: '457763',
          groupedItems: [
            {
              amount: 0,
              description: 'Apple Gouda Sausage',
              freeQuantity: 0,
              fullDescription:
                'With no antibiotics or hormones added ever, it’s food you can feel good about. 12 oz. of savory pork sausage.',
              image:
                'https://www.butcherbox.com/apiv2/files/products/NIman_Apple_Gouda_Sausage.jpg',
              meatGroup: 'prepared',
              packSize: '1 x 12 oz pack',
              price: 328,
              quantity: 1,
              sku: '971856',
            },
          ],
          created_at: [
            {
              created_at: '2022-10-19 13:06:52',
              id: 144146889,
              expire_on: '2022-10-23',
            },
          ],
          markupPrice: '7.00',
        }
      )
      response.data.invoiceItems.forEach((deal) => {
        // Deals should always have a groupedItems array of at least one
        // This work is in progress in APIv3
        if (deal?.groupedItems.length) {
          deal.groupedItems = [deal]
        }
      })
    }
    return response
  },
}

export const addTurkeysToUpcomingOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Add Free-Range Turkey (10-14 lbs)',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.UpcomingOrder>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      const turkey: schemata.BoxItem = {
        amount: 0,
        description: 'Free-Range Turkey (10-14 lbs)',
        fullDescription: 'Free-Range Turkey (10-14 lbs)',
        image:
          'https://www.butcherbox.com/apiv2/files/products/turkey_3_2_add-ons_monthly_specials8.jpg',
        meatGroup: 'turkey',
        packSize: '1 x 10-14 lbs',
        price: 2000,
        quantity: 1,
        sku: '971794',
      }

      const upcomingOrder = response.data

      upcomingOrder.invoiceItems.push({
        ...turkey,
        sku: '456123',
        groupedItems: [turkey],
        offerGroup: null,
      })
    }
    return response
  },
}

export const addOffersToSubscription: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Add Offers',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.UpcomingOrder>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      const upcomingOrder = response.data

      upcomingOrder.subscription.box.offers = [
        {
          amount: 0,
          description: 'Ground Beef for Life',
          fullDescription: 'Yayyyyyyy',
          groupedItems: [
            {
              amount: 0,
              description: 'Ground Beef',
              fullDescription: '',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
              meatGroup: 'beef',
              packSize: '1 x 10 oz pack',
              price: 0,
              quantity: 1,
              sku: '971726',
            },
          ],
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          meatGroup: 'beef',
          packSize: '2 lbs in every box',
          price: 0,
          quantity: 1,
          sku: '1234',
          isPausable: true,
          status: 'active',
          offerGroup: 'ground_beef_for_life',
        },
        {
          amount: 0,
          description: 'Bacon For Life',
          fullDescription:
            "Pederson's Sugar-Free Nitrate-Free Bacon. Whole 30 Approved!",
          groupedItems: [
            {
              amount: 0,
              description: 'Bacon For Life',
              fullDescription:
                "Pederson's Sugar-Free Nitrate-Free Bacon. Whole 30 Approved!",
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon_new1.jpg',

              meatGroup: 'pork',
              packSize: '1 x 10oz pack',
              price: 0,
              quantity: 1,
              sku: '971365',
            },
          ],
          image:
            'https://www.butcherbox.com/apiv2/files/products/Bacon_new1.jpg',

          isPausable: true,
          meatGroup: 'pork',
          offerGroup: 'bacon_for_life',
          packSize: '1 x 10oz pack',
          price: 0,
          quantity: 1,
          sku: '971365',
          status: 'active',
        },
      ]
    }
    return response
  },
}

export const addCreditToMostRecentOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS],
  name: 'Recent Order: Simulate Credit',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === endpoints.GET_ORDERS(user.id)) {
      const [order] = response.data.data

      order.creditAmount = 10000
    }

    return response
  },
}

export const addCouponToMostRecentOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS],
  name: 'Recent Order: Simulate Coupon',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === endpoints.GET_ORDERS(user.id)) {
      const [order] = response.data.data

      order.discountAmount = -10000
    }

    return response
  },
}

export const setRecentOrderToProcessingWithBoxItems: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name: 'Recent Order: Set tracking information to processing',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const order = response.data.data

      order[0].trackingInformation.status = 'processing'
    }

    return response
  },
}

export const setRecentOrderToInTransitWithBoxItems: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name: 'Recent Order: Set tracking information to In transit',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const order = response.data.data

      order[0].trackingInformation.status = 'in_transit'
      order[0].trackingInformation.shipmentDate = '2022-11-21'
    }

    return response
  },
}

export const setAllBoxItemsToUnavailableStatus: Simulation = {
  cacheKeys: [constants.CACHE_KEY_BOX_ITEMS],
  name: 'Upcoming Order: Set All Contents to Unavailable Status',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.AvailableCustomCuts>
  ) => {
    if (response.config.url === endpoints.GET_CUSTOMER_BOX_ITEMS(user.id)) {
      Object.values(response.data).forEach((products) => {
        products.forEach((product) => {
          product.isUnavailable = true
        })
      })
    }
    return response
  },
}

export const returnCreditInUpcomingOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Set Credit to $10',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.UpcomingOrder>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      response.data.creditAmount = -1000
    }

    return response
  },
}

export const mostRecentOrderInTransit: Simulation = {
  cacheKeys: [constants.CACHE_KEY_LATEST_SHIPMENT],
  name: 'Latest Shipment: Set to Processing Status',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.Shipment>
  ) => {
    if (response.config.url === getLatestShipment(user.id, subscription.id)) {
      response.data.trackingInformation.status = 'processing'
    }

    return response
  },
}

export const markMostRecentOrderShipmentDelivered: Simulation = {
  cacheKeys: [constants.CACHE_KEY_LATEST_SHIPMENT],
  name: 'Latest Shipment: Set to Delivered Status',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.Shipment>
  ) => {
    if (response.config.url === getLatestShipment(user.id, subscription.id)) {
      response.data.trackingInformation.status = 'delivered'
    }

    return response
  },
}

export const markMostRecentOrderShipmentDeliveredYesterday: Simulation = {
  userPrompt: 'How many days?',
  cacheKeys: [constants.CACHE_KEY_LATEST_SHIPMENT],
  name: 'Latest Shipment: Set to Delivered X Days Ago',
  responseInterceptor: (user, subscription, daysAgoStr) => (
    response: AxiosResponse<schemata.Shipment>
  ) => {
    const daysAgo = Number(daysAgoStr)

    if (!daysAgo) return response

    if (response.config.url === getLatestShipment(user.id, subscription.id)) {
      response.data.trackingInformation.status = 'delivered'
      response.data.trackingInformation.deliveryDate = dayjs()
        .subtract(daysAgo, 'day')
        .toString()
    }

    return response
  },
}

export const markMostRecentOrderShipmentDeliveredTwoMonthsAgo: Simulation = {
  cacheKeys: [constants.CACHE_KEY_LATEST_SHIPMENT],
  name: 'Latest Shipment: Set to Delivered 2 Months Ago',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.Shipment>
  ) => {
    if (response.config.url === getLatestShipment(user.id, subscription.id)) {
      response.data.trackingInformation.status = 'delivered'
      response.data.trackingInformation.deliveryDate = dayjs()
        .subtract(2, 'month')
        .toString()
    }

    return response
  },
}

export const mostRecentOrderHasTrackingUrl: Simulation = {
  cacheKeys: [constants.CACHE_KEY_LATEST_SHIPMENT],
  name: 'Latest Shipment: Set to In Transit status with Tracking URL',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.Shipment>
  ) => {
    if (response.config.url === getLatestShipment(user.id, subscription.id)) {
      response.data.trackingInformation.status = 'in_transit'
      response.data.trackingInformation.url =
        'https://www.youtube.com/watch?v=dQw4w9WgXcQ' // 🕺🏼
    }

    return response
  },
}

/**
 * This simulation can be removed once APIv3 work is complete to support groupedItem
 * behavior on member deals according to the following criteria:
 *
 * - Invoice items always contain a groupedItems array with at least one item
 * - For single-item invoice items, the sku of the groupedItem matches the sku of the invoice item
 * - For multi-product invoice items, each sku contained in the groupedItems is unique
 */
export const addGroupedItemsToUpcomingOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Add Member Deals',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.UpcomingOrder>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      response.data.invoiceItems.push(
        {
          amount: 0,
          description: 'Bacon Blast (BOGO)',
          fullDescription:
            "So much bacon you'll die, but happy and full of pork",
          groupedItems: [
            {
              amount: 0,
              description: 'ButcherBox Bacon',
              fullDescription: '',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
              meatGroup: 'pork',
              packSize: '1 x 10 oz pack',
              price: 1234,
              quantity: 2,
              sku: '971726',
            },
            {
              amount: 0,
              description: 'ButcherBox Bacon',
              fullDescription: '',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
              meatGroup: 'pork',
              packSize: '1 x 10 oz pack',
              price: 0,
              quantity: 1,
              sku: '971726',
            },
          ],
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          meatGroup: 'pork',
          offerGroup: null,
          packSize: '3lbs',
          price: 1234,
          quantity: 1,
          sku: '1234',
        },
        {
          amount: 0,
          description: 'Bacon Blast',
          fullDescription:
            "So much bacon you'll die, but happy and full of pork",
          groupedItems: [
            {
              amount: 0,
              description: 'ButcherBox Bacon',
              fullDescription: '',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
              meatGroup: 'pork',
              packSize: '1 x 10 oz pack',
              price: 1234,
              quantity: 3,
              sku: '971726',
            },
          ],
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          meatGroup: 'pork',
          offerGroup: null,
          packSize: '3lbs',
          price: 5432,
          quantity: 1,
          sku: '1235',
        }
      )
      response.data.invoiceItems.forEach((deal) => {
        // Deals should always have a groupedItems array of at least one
        // This work is in progress in APIv3
        if (deal?.groupedItems.length) {
          deal.groupedItems = [deal]
        }
      })
    }
    return response
  },
}

export const addExpiringDealToUpcomingOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Add Expiring Member Deals',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.UpcomingOrder>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      response.data.invoiceItems.push({
        amount: 0,
        description: 'Bacon Blast (BOGO)',
        fullDescription: "So much bacon you'll die, but happy and full of pork",
        groupedItems: [
          {
            amount: 0,
            description: 'ButcherBox Bacon',
            fullDescription: '',
            image:
              'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
            meatGroup: 'pork',
            packSize: '1 x 10 oz pack',
            price: 1234,
            quantity: 2,
            sku: '971726',
          },
        ],
        created_at: [
          {
            created_at: dayjs().subtract(2, 'd').format(),
            expire_on: dayjs().add(4, 'd').format(),
            id: 2,
          },
        ],
        image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
        meatGroup: 'pork',
        offerGroup: null,
        packSize: '3lbs',
        price: 1234,
        quantity: 1,
        sku: '1234',
      })
      response.data.invoiceItems.forEach((deal) => {
        // Deals should always have a groupedItems array of at least one
        // This work is in progress in APIv3
        if (deal?.groupedItems.length) {
          deal.groupedItems = [deal]
        }
      })
    }
    return response
  },
}

export const enableSaturdayDelivery: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CUSTOMER],
  name: 'Saturday Delivery: Enable',
  responseInterceptor: (user) => (response: AxiosResponse<schemata.User>) => {
    if (response.config.url === endpoints.GET_CUSTOMER(user.id)) {
      response.data.shipZone.noSaturday = false
    }

    return response
  },
}

export const customerErrorResponse: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CUSTOMER],
  name: 'Customer: Error Response',
  responseInterceptor: (user) => (response: AxiosResponse<any>) => {
    if (response.config.url === endpoints.GET_CUSTOMER(user.id)) {
      throw new Error()
    }

    return response
  },
}

export const disableSaturdayDelivery: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CUSTOMER],
  name: 'Saturday Delivery: Disable',
  responseInterceptor: (user) => (response: AxiosResponse<schemata.User>) => {
    if (response.config.url === endpoints.GET_CUSTOMER(user.id)) {
      response.data.shipZone.noSaturday = true
    }

    return response
  },
}

export const enableSingleLanderDeal: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_DEAL_LANDER],
  redirectTo: '/account/deals?lander=deals-1',
  name: 'Deals: Show For-life Lander Deal on /deals-1',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.LanderDeal[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_DEALS(user.id)}/deals-1`) {
      response.data.data = [
        {
          amount: 1,
          backgroundHeroImage:
            'https://www.butcherbox.com/wp-content/uploads/2019/04/proteins.jpg',
          description: 'Bacon for life',
          dealDescription:
            'Get a free pack of bacon in every box for the lifetime of your membership',
          dealName: 'Bacon for Life',
          dealPrice: 2500,
          fullDescription:
            'Get a free pack of bacon in every box for the lifetime of your membership',
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          dealImage:
            'https://www.butcherbox.com/wp-content/uploads/2020/08/chicken_and_ribeyes.jpg',
          individualLineItem: true,
          shouldRedirect: false,
          redirectionUrl: null,
          redirectionCta: null,
          isSoldOut: false,
          isUnavailable: false,
          headerText: '<span>Exclusive</span> Member Deals',
          maxQuantity: 1,
          meatGroup: 'pork',
          offerGroup: 'bacon_for_life',
          packSize: '1 x 10ox pack',
          price: 9900,
          quantity: 1,
          sku: '456017',
          strikethroughPrice: 0,
          disclaimerText: 'dummy disclaimer',
          experimentId: null,
          altDescription: null,
          altTitle: null,
          altImage: null,
          expireOn: null,
          daysToExpire: null,
          dealLanderType: 'deals',
          dealLanderHeroHeading: 'Member Deals',
        },
      ]
    }

    return response
  },
}

export const enablePastureBirdDealLander: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_DEAL_LANDER],
  redirectTo: '/account/deals?lander=deals-1',
  name: 'Deals: Show Pasturebird Lander Deal on /deals-1',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.LanderDeal[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_DEALS(user.id)}/deals-1`) {
      response.data.data = [
        {
          amount: 1,
          backgroundHeroImage:
            'https://www.butcherbox.com/wp-content/uploads/2019/04/proteins.jpg',
          description: 'Pasture-raised 8-piece chicken ',
          dealDescription:
            '2 wings, 2 drums, 2 bone-in thighs, and 2 bone-in breasts. Skin-on. White meat packaged separately from dark meat. ',
          dealName: 'Pasture-raised 8-piece chicken',
          dealPrice: 9900,
          fullDescription:
            '2 wings, 2 drums, 2 bone-in thighs, and 2 bone-in breasts. Skin-on. White meat packaged separately from dark meat. ',
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          dealImage:
            'https://www.butcherbox.com/wp-content/uploads/2020/08/chicken_and_ribeyes.jpg',
          individualLineItem: false,
          shouldRedirect: false,
          redirectionUrl: null,
          redirectionCta: null,
          isSoldOut: false,
          isUnavailable: false,
          headerText: '<span>Exclusive</span> Member Deals',
          maxQuantity: 5,
          meatGroup: 'beef',
          packSize: '20 oz ribeyes + 3 lbs chicken breast',
          price: 9900,
          quantity: 1,
          sku: '971585',
          strikethroughPrice: 0,
          offerGroup: null,
          disclaimerText: 'dummy disclaimer',
          experimentId: null,
          altDescription: null,
          altTitle: null,
          altImage: null,
          expireOn: null,
          daysToExpire: null,
          dealLanderType: 'deals',
          dealLanderHeroHeading: 'Member Deals',
        },
      ]
    }

    return response
  },
}

export const enableMultipleLanderDeals: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_DEAL_LANDER],
  redirectTo: '/account/deals?lander=deals-2',
  name: 'Deals: Show Multiple Lander Deals on /deals-2',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.LanderDeal[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_DEALS(user.id)}/deals-2`) {
      response.data.data = [
        {
          amount: 1,
          backgroundHeroImage:
            'https://www.butcherbox.com/wp-content/uploads/2019/12/image2.jpg',
          description: 'Thick Cut Not Bacon',
          dealDescription: 'Thick Cut Not Bacon',
          dealName: 'Thick Cut Not Bacon',
          dealPrice: 1200,
          fullDescription:
            'This is a description of a product. It is making you want to purchase this product.',
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          dealImage:
            'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          individualLineItem: true,
          isSoldOut: false,
          shouldRedirect: false,
          redirectionUrl: null,
          redirectionCta: null,
          isUnavailable: false,
          headerText: '7 Days of Deals',
          maxQuantity: 3,
          meatGroup: 'bacon',
          packSize: '1 x 10 oz pack',
          price: 1200,
          quantity: 1,
          sku: '971726',
          strikethroughPrice: 0,
          offerGroup: null,
          disclaimerText: 'dummy disclaimer',
          experimentId: null,
          altDescription: null,
          altTitle: null,
          altImage: null,
          expireOn: null,
          daysToExpire: '80',
          dealLanderType: 'deals',
          dealLanderHeroHeading: 'Member Deals',
        },
        {
          amount: 1,
          backgroundHeroImage:
            'https://www.butcherbox.com/wp-content/uploads/2019/12/image2.jpg',
          description: 'Ground Turkey',
          dealDescription: 'Ground Turkey',
          dealName: 'Ground Turkey',
          dealPrice: 1000,
          fullDescription:
            'Bringing back a member favorite! Get 2 lbs of our deliciously lean ground turkey in your next box.',
          image:
            'https://www.butcherbox.com/wp-content/uploads/2019/03/Ground_Turlkey_cut_sm.jpg',
          dealImage:
            'https://www.butcherbox.com/wp-content/uploads/2019/03/Ground_Turlkey_cut_sm.jpg',
          individualLineItem: true,
          shouldRedirect: false,
          redirectionUrl: null,
          redirectionCta: null,
          isUnavailable: false,
          isSoldOut: true,
          headerText: '7 Days of Deals',
          maxQuantity: 3,
          meatGroup: 'turkey',
          packSize: '1 x 10 oz pack',
          price: 1400,
          quantity: 1,
          sku: '971731',
          strikethroughPrice: 0,
          offerGroup: null,
          disclaimerText: 'dummy disclaimer',
          experimentId: null,
          altDescription: null,
          altTitle: null,
          altImage: null,
          expireOn: null,
          daysToExpire: null,
          dealLanderType: 'deals',
          dealLanderHeroHeading: 'Member Deals',
        },
      ]
    }

    return response
  },
}

export const showSoldOutLanderDeal: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_DEAL_LANDER],
  redirectTo: '/account/deals?lander=deals-3',
  name: 'Deals: Show Sold Out Lander Deal on /deals-3',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.LanderDeal[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_DEALS(user.id)}/deals-3`) {
      response.data.data = [
        {
          amount: 1,
          backgroundHeroImage:
            'https://www.butcherbox.com/wp-content/uploads/2019/12/image2.jpg',
          description: 'Thick Cut Bacon',
          dealDescription: 'Thick Cut Bacon',
          dealName: 'Thick Cut Bacon',
          dealPrice: 1200,
          fullDescription:
            'Add a pack of our premium delicious bacon and level up your breakfast!',
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          dealImage:
            'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',

          individualLineItem: true,
          shouldRedirect: false,
          redirectionUrl: null,
          redirectionCta: null,
          isSoldOut: true,
          isUnavailable: false,
          headerText: 'Only for you',
          maxQuantity: 3,
          meatGroup: 'pork',
          packSize: '1 x 10 oz pack',
          price: 1200,
          quantity: 1,
          sku: '971726',
          strikethroughPrice: 0,
          offerGroup: null,
          disclaimerText: 'dummy disclaimer',
          experimentId: null,
          altDescription: null,
          altTitle: null,
          altImage: null,
          expireOn: null,
          daysToExpire: null,
          dealLanderType: 'deals',
          dealLanderHeroHeading: 'Member Deals',
        },
      ]
    }

    return response
  },
}

export const markSomeDealsAsUnavailable: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_DEALS],
  name: 'Deals: Simulate Sold Out',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Deal[] }>
  ) => {
    if (response.config.url === endpoints.GET_DEALS(user.id)) {
      const deals = response.data.data

      deals.forEach((deal, idx) => {
        if (idx % 3 === 0) {
          deal.isSoldOut = true
        }
      })
    }

    return response
  },
}

export const markSomeDealsAsIndividualLineItem: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_DEALS],
  name: 'Deals: Simulate Individual Line Items',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.LanderDeal[] }>
  ) => {
    if (response.config.url === endpoints.GET_DEALS(user.id)) {
      const deals = response.data.data

      const dealsNotSoldOut = deals.filter((deal) => !deal.isSoldOut)
      sampleSize(dealsNotSoldOut, 3).forEach((deal) => {
        deal.individualLineItem = true
      })
    }

    return response
  },
}

export const markSomeDealsIndividualLineItemAsExternalRedirect: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_DEALS],
  name: 'Deals: Show deals page where first deal will redirect externally',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.LanderDeal[] }>
  ) => {
    if (response.config.url === endpoints.GET_DEALS(user.id)) {
      response.data.data[0] = {
        ...response.data.data[0],
        isSoldOut: false,
        individualLineItem: true,
        shouldRedirect: true,
        redirectionUrl: 'https://butcherbox.com',
        redirectionCta: 'Order Now',
      }
    }
    return response
  },
}

export const markSomeDealsRegularItemAsExternalRedirect: Simulation = {
  cacheKeys: [constants.CACHE_KEY_MEMBER_DEALS],
  name: 'Deals: Show full-page deal with external redirect URL',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.LanderDeal[] }>
  ) => {
    if (response.config.url === endpoints.GET_DEALS(user.id)) {
      const deals = response.data.data
      const dealsNotIndividualItem = deals.filter(
        (deal) => !deal.individualLineItem
      )
      dealsNotIndividualItem.forEach((deal, idx) => {
        if (idx % 3 === 0) {
          deal.isSoldOut = false
          deal.shouldRedirect = true
          deal.redirectionUrl = 'https://smoothiebox.com'
          deal.redirectionCta = 'Order Now'
        }
      })
    }
    return response
  },
}

export const addDiscountCodeToCheckoutSettings: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CHECKOUT_SETTINGS],
  name: 'Checkout Settings: Add Discount',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSettings>
  ) => {
    if (response.config.url === endpoints.GET_CHECKOUT_SETTINGS) {
      response.data.discountCode = 'foo'
    }
    return response
  },
}

export const addOfferToCheckoutSettings: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CHECKOUT_SETTINGS],
  name: 'Checkout Settings: Add Offer',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSettings>
  ) => {
    if (response.config.url === endpoints.GET_CHECKOUT_SETTINGS) {
      response.data.offer = {
        amount: 0,
        description: 'Ground Beef for Life',
        fullDescription: 'Yayyyyyyy',
        image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
        groupedItems: [
          {
            amount: 0,
            description: 'Ground Beef for Life',
            fullDescription: 'Yayyyyyyy',
            image:
              'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
            meatGroup: 'pork',
            packSize: '2 lbs in every box',
            price: 0,
            quantity: 1,
            sku: '1234',
          },
        ],
        meatGroup: 'pork',
        packSize: '2 lbs in every box',
        price: 0,
        quantity: 1,
        sku: '1234',
        isPausable: true,
        status: 'active',
        offerGroup: 'ground_beef_for_life',
      }
    }
    return response
  },
}

export const addShipmentWeekToUpcomingOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_UPCOMING_ORDER],
  name: 'Upcoming Order: Add Shipment Week (4 weeks ahead of today)',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.UpcomingOrder>
  ) => {
    if (
      response.config.url ===
      endpoints.GET_UPCOMING_ORDER(user.id, subscription.id)
    ) {
      response.data.shipmentWeek = dayjs().add(4, 'week').format('YYYY-MM-DD')
    }

    return response
  },
}

export const addDiscountToCheckoutSummary: Simulation = {
  cacheKeys: [],
  feedback:
    'The simulation will be visible after you submit one of the payment forms.',
  name: 'Checkout Summary: Add Discount',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSummary>
  ) => {
    if (response.config.url === endpoints.UPDATE_CHECKOUT_SUMMARY) {
      response.data.discountAmount = -10000
      response.data.total -= 10000
    }

    return response
  },
}

export const addExtraProductsToCheckoutSummary: Simulation = {
  cacheKeys: [],
  feedback:
    'The simulation will be visible after you submit one of the payment forms.',
  name: 'Checkout Summary: Add Extras',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSummary>
  ) => {
    if (response.config.url === endpoints.UPDATE_CHECKOUT_SUMMARY) {
      response.data.extras.push({
        amount: 0,
        description: 'Extra Tasty Bacon',
        fullDescription: '',
        groupedItems: [],
        image: '',
        meatGroup: 'pork',
        packSize: '12oz',
        price: 0,
        quantity: 1,
        sku: '1234',
      })
    }

    return response
  },
}

export const addShipmentWeekToMostRecentOrder: Simulation = {
  cacheKeys: [constants.CACHE_KEY_LATEST_SHIPMENT],
  name: 'Latest Shipment: Add Shipment Week (4 weeks ahead of today)',
  responseInterceptor: (user, subscription) => (
    response: AxiosResponse<schemata.Shipment>
  ) => {
    if (response.config.url === getLatestShipment(user.id, subscription.id)) {
      response.data.trackingInformation.deliveryDate = null
      response.data.shipmentWeek = dayjs().add(4, 'week').format('YYYY-MM-DD')
    }

    return response
  },
}

export const changeBillingInformationNameToNull: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CUSTOMER],
  name: 'Account: Change billing information name to null',
  responseInterceptor: (user) => (response: AxiosResponse<schemata.User>) => {
    if (response.config.url === endpoints.GET_CUSTOMER(user.id)) {
      response.data.billingInformation.name = null
    }

    return response
  },
}

export const giveSavedRecipes: Simulation = {
  cacheKeys: [constants.CACHE_KEY_USER_ACTION],
  name: 'User Action: Saved Recipe List & redirect to Recipe Box',
  redirectTo: '/account/saved-recipes',
  responseInterceptor: (user) => (
    response: AxiosResponse<schemata.UserAction<'savedRecipes'>>
  ) => {
    if (
      response.config.url ===
      `${endpoints.USER_ACTION(user.id)}?actionName=savedRecipes`
    ) {
      response.data = {
        data: {
          actionName: 'savedRecipes',
          actionData: {
            recipeIdList: [28981, 28979, 28971, 28977, 28914, 28964],
          },
        },
      }
    }
    return response
  },
}

export const simulateNewAccountWithShipmentWeek: Simulation = {
  cacheKeys: [constants.CACHE_KEY_ORDERS, constants.CACHE_KEY_SUBSCRIPTION],
  name: 'Account: Simulate New Account w/ Shipment Week',
  responseInterceptor: (user) => (
    response: AxiosResponse<{
      data: schemata.Order[] | schemata.Subscription[]
    }>
  ) => {
    if (
      response.config.url === endpoints.GET_ORDERS(user.id) &&
      'trackingInformation' in response.data.data[0]
    ) {
      response.data.data = [
        {
          ...response.data.data[0],
          datePaid: dayjs().format('YYYY-MM-DD'),
          trackingInformation: {
            deliveryDate: null,
            shipmentDate: null,
            number: null,
            status: null,
            url: null,
            ftpId: null,
          },
          shipmentWeek: dayjs().add(4, 'week').format('YYYY-MM-DD'),
        },
      ]
    }

    if (
      response.config.url === getSubscriptions(user.id) &&
      'periodEndDate' in response.data.data[0]
    ) {
      response.data.data[0].periodEndDate = dayjs()
        .add(6, 'week')
        .format('YYYY-MM-DD')
    }

    return response
  },
}

export const addBannerMessageToCheckoutSettings: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CHECKOUT_SETTINGS],
  name: 'Checkout Settings: Add Banner Message',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSettings>
  ) => {
    if (response.config.url === endpoints.GET_CHECKOUT_SETTINGS) {
      response.data.bannerMessage =
        'Buffalo buffalo buffalo, buffalo buffalo buffalo buffalo.'
    }

    return response
  },
}

export const addShipmentWeeksToCheckoutSettings: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CHECKOUT_SETTINGS],
  name: 'Checkout Settings: Add Shipment Weeks',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSettings>
  ) => {
    if (response.config.url === endpoints.GET_CHECKOUT_SETTINGS) {
      response.data.shipmentWeeks = [
        dayjs().add(2, 'week').format('YYYY-MM-DD'),
        dayjs().add(4, 'week').format('YYYY-MM-DD'),
        dayjs().add(6, 'week').format('YYYY-MM-DD'),
        dayjs().add(8, 'week').format('YYYY-MM-DD'),
      ]
    }

    return response
  },
}

export const addDefaultFrequencyToCheckoutSettings: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CHECKOUT_SETTINGS],
  name: 'Checkout Settings: Add Default Frequency (every 2 months)',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSettings>
  ) => {
    if (response.config.url === endpoints.GET_CHECKOUT_SETTINGS) {
      response.data.defaultFrequency = 'every_2_months'
    }

    return response
  },
}

export const addNullDefaultFrequencyToCheckoutSettings: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CHECKOUT_SETTINGS],
  name: 'Checkout Settings: Add Default Frequency (null / wp-admin toggle off)',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSettings>
  ) => {
    if (response.config.url === endpoints.GET_CHECKOUT_SETTINGS) {
      response.data.defaultFrequency = null
    }

    return response
  },
}

export const addExitIntentOfferToCheckoutSettings: Simulation = {
  cacheKeys: [constants.CACHE_KEY_CHECKOUT_SETTINGS],
  name: 'Checkout Settings: Add Exit Intent Offer',
  responseInterceptor: () => (
    response: AxiosResponse<schemata.CheckoutSettings>
  ) => {
    if (response.config.url === endpoints.GET_CHECKOUT_SETTINGS) {
      response.data.exitIntentSettings = {
        buttonText: 'GET MY FREE BACON',
        description:
          "Here's <span>1 pack of FREE</span> Bacon: <br> order now!",
        footer: "No thanks, I'll pass.",
        heading: 'Wait!',
        image:
          'https://www.butcherbox.com/wp-content/uploads/2015/07/bacon1-min.png',
        product: {
          description: 'Delicious bacon',
          sku: '971726',
        },
        tagline: 'Bonus Item For You',
      }
    }

    return response
  },
}

const curatedNavigateState: ICongratulationsState = {
  email: '213123@asdf.com',
  phoneNumber: '33523525235',
  shipping: {
    addressLineTwo: null,
    firstName: 'asdf',
    lastName: 'asdf',
    email: '213123@asdf.com',
    phoneNumber: '33523525235',
    addressLineOne: '131 Washington St',
    city: 'Cambridge',
    state: 'MA',
    postalCode: '02139',
    deliveryInstructions: null,
  },
  billing: {
    addressLineTwo: null,
    firstName: 'asdf',
    lastName: 'asdf',
    addressLineOne: '131 Washington St',
    city: 'Cambridge',
    state: 'MA',
    postalCode: '02139',
    name: 'asdf asdf',
    stripeToken: 'tok_1HuLCRA33rLV7rfIeEs5jFdW',
  },
  creditAmount: 0,
  discountCode: 'discount1234',
  discountAmount: 100,
  extrasPrice: 0,
  invoiceItemsPrice: 0,
  shippingPrice: 0,
  subscriptionPrice: 12900,
  subtotal: 30400,
  taxAmount: 0,
  taxRate: 0,
  total: 30300,
  extras: [
    {
      amount: 0,
      description: 'Bacon For Life',
      image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
      meatGroup: 'pork',
      packSize: '1 x 10 oz pack',
      price: 0,
      quantity: 1,
      sku: '971726',
      fullDescription: 'foo', // see: https://nbox-341.atlassian.net/browse/ENG-1053
      groupedItems: [
        {
          amount: 0,
          description: 'Bacon For Life',
          image: 'https://www.butcherbox.com/apiv2/files/products/Bacon11.jpg',
          meatGroup: 'pork',
          packSize: '1 x 10 oz pack',
          price: 0,
          quantity: 1,
          sku: '971726',
          fullDescription: 'foo', // see: https://nbox-341.atlassian.net/browse/ENG-1053
        },
      ],
    },
  ],
  invoiceItems: [
    {
      amount: 5200,
      description: 'Lobster Bundle',
      fullDescription: '',
      image:
        'https://www.butcherbox.com/wp-content/uploads/2015/07/Cold-cracked-Tails.jpg',
      meatGroup: 'seafood',
      packSize: '',
      price: 5200,
      quantity: 1,
      sku: '457646',
      groupedItems: [
        {
          amount: 0,
          description: 'Lobster Bundle',
          fullDescription: '',
          image:
            'https://www.butcherbox.com/wp-content/uploads/2015/07/Cold-cracked-Tails.jpg',
          meatGroup: 'seafood',
          packSize: '',
          price: 5200,
          quantity: 1,
          sku: '457646',
        },
      ],
    },
    {
      amount: 2200,
      description: 'Flank Steak',
      fullDescription:
        "Flank steak is a large, flat cut with an unmistakable cross-grain. It's quick-cooking with a deep, meaty flavor. Save $2 now on this 1.75 lb cut!",
      image:
        'https://www.butcherbox.com/wp-content/uploads/2021/09/Flank-Steak_20ff.jpg',
      meatGroup: 'beef',
      packSize: '',
      price: 2200,
      quantity: 1,
      sku: '457641',
      groupedItems: [
        {
          amount: 0,
          description: 'Flank Steak',
          fullDescription:
            "Flank steak is a large, flat cut with an unmistakable cross-grain. It's quick-cooking with a deep, meaty flavor. Save $2 now on this 1.75 lb cut!",
          image:
            'https://www.butcherbox.com/wp-content/uploads/2021/09/Flank-Steak_20ff.jpg',
          meatGroup: 'beef',
          packSize: '',
          price: 2200,
          quantity: 1,
          sku: '457641',
        },
      ],
    },
    {
      amount: 3600,
      description: 'Breaded Chicken Strips',
      fullDescription:
        "Our chicken strips are made with USDA organic, free-range white meat and non-GMO ingredients. They're fully cooked, so just heat them up for a fast, easy, and nutritious meal anytime. Get 1.5 lbs in your next box, while supplies last!",
      image:
        'https://www.butcherbox.com/wp-content/uploads/2022/06/Screen-Shot-2022-06-09-at-3.10.30-PM.png',
      meatGroup: 'chicken',
      packSize: '',
      price: 1800,
      quantity: 2,
      sku: '457664',
      groupedItems: [
        {
          amount: 0,
          description: 'Breaded Chicken Strips',
          fullDescription:
            "Our chicken strips are made with USDA organic, free-range white meat and non-GMO ingredients. They're fully cooked, so just heat them up for a fast, easy, and nutritious meal anytime. Get 1.5 lbs in your next box, while supplies last!",
          image:
            'https://www.butcherbox.com/wp-content/uploads/2022/06/Screen-Shot-2022-06-09-at-3.10.30-PM.png',
          meatGroup: 'chicken',
          packSize: '',
          price: 1800,
          quantity: 1,
          sku: '457664',
        },
      ],
    },
  ],
  subscription: {
    id: 3583908,
    interval: 'every_month',
    month: 1,
    periodEndDate: dayjs().add(6, 'week').format('YYYY-MM-DD'),
    status: 'active',
    box: {
      addonsPrice: 0,
      basePrice: 12900,
      name: 'Beef Chicken And Pork',
      offersPrice: 0,
      size: 'classic',
      type: 'beef_chicken_pork',
      invoiceItems: [],
      invoiceItemsPrice: 0,
      addons: [
        {
          amount: 1500,
          description: 'Ground Beef (85/15) ',
          fullDescription:
            'This finely ground beef has a meat-to-fat ratio of 85% to 15%. Saut&eacute; for a quick weeknight dinner, or make into burgers and grill.',
          groupedItems: [
            {
              amount: 0,
              description: 'Free Ground Beef For Life of Membership',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Ground-Beef_new1.jpg',

              meatGroup: 'beef',
              packSize: '2 x 1 lb packs',
              price: 0,
              quantity: 1,
              sku: '971314',
              fullDescription: 'foo',
            },
          ],
          image:
            'https://www.butcherbox.com/apiv2/files/products/Ground-Beef_new1.jpg',
          meatGroup: 'beef',
          packSize: '2 x 1 lb packs',
          price: 1500,
          quantity: 1,
          sku: '971314',
        },
        {
          amount: 5000,
          description: 'Wild Alaskan Sockeye Salmon',
          fullDescription:
            'Our wild-caught sockeye salmon hails from Bristol Bay, Alaska. Sear in a pan or grill for unforgettable flavor.',
          groupedItems: [
            {
              amount: 0,
              description: 'Wild Alaskan Sockeye Salmon',
              image:
                'https://www.butcherbox.com/apiv2/files/products/salmon_255x1701.jpg',

              meatGroup: 'seafood',
              packSize: '1 x 2 lbs pack',
              price: 2500,
              quantity: 2,
              sku: '971801',
              fullDescription: 'foo', // see: https://nbox-341.atlassian.net/browse/ENG-1053
            },
          ],
          image:
            'https://www.butcherbox.com/apiv2/files/products/salmon_255x1701.jpg',
          meatGroup: 'seafood',
          packSize: '1 x 2 lbs pack',
          price: 2500,
          quantity: 2,
          sku: '971801',
        },
      ],
      items: [],
      offers: [
        {
          amount: 0,
          description: 'WINGS FOR LIFE',
          fullDescription: 'Organic Chicken. No added hormones or antibiotics.',
          image:
            'https://www.butcherbox.com/apiv2/files/products/Chicken-Wings_latest.jpg',
          isPausable: true,
          meatGroup: 'chicken',
          offerGroup: 'wings_for_life',
          packSize: '3 x 1lb packs',
          price: 0,
          quantity: 1,
          sku: '971635',
          status: 'active',
          isUnavailable: false,
          groupedItems: [
            {
              amount: 0,
              description: 'WINGS FOR LIFE',
              fullDescription:
                'Organic Chicken. No added hormones or antibiotics.',
              image:
                'https://www.butcherbox.com/apiv2/files/products/Chicken-Wings_latest.jpg',
              meatGroup: 'chicken',
              packSize: '3 x 1lb packs',
              price: 0,
              quantity: 1,
              sku: '971635',
              isUnavailable: false,
            },
          ],
        },
      ],
    },
  },
  accountCreationToken: '9b2e2686d76e6174',
  customer: {
    id: 3583918,
    phoneNumber: '33523525235',
    firstName: 'Spongebob',
    lastName: 'Squarepants',
    fullName: 'Spongebob Squarepants',
    emailAddress: 'spongebob@bikinibottom.net',
    joinedAt: '',
    stripeId: 'cus_123445434443',
    shipZone: {
      postalCode: '12345',
      preferredCarrier: 'LAZER ship',
      preferredFacility: 'OKC',
      daysInTransit: 2,
      noSaturday: false,
    },
    claimedDealsCount: 0,
    billingInformation: {
      addressLineOne: '131 Washington St',
      addressLineTwo: null,
      brand: 'Visa',
      city: 'Cambridge',
      lastFourDigits: '4242',
      postalCode: '02139',
      state: 'MA',
      name: 'Spongebob Squarepants',
    },
    shippingAddress: {
      addressLineOne: '131 Washington St',
      addressLineTwo: null,
      city: 'Cambridge',
      deliveryInstructions: null,
      firstName: 'asdf',
      lastName: 'asdf',
      postalCode: '02139',
      state: 'MA',
    },
    anonymousId: '7e1ab1a7-46a8-4032-83e6-8ce878a7b46b',
    paymentGateway: 'stripe',
  },
}

export const viewCheckoutCongratsPageCurated: Simulation = {
  cacheKeys: [],
  redirectTo: '/congratulations/',
  name: 'Checkout: View Checkout Congrats Page (Curated)',
  navigateState: curatedNavigateState,
}

export const viewCheckoutCongratsPageCustom: Simulation = {
  cacheKeys: [],
  redirectTo: '/congratulations/',
  name: 'Checkout: View Checkout Congrats Page: (Custom)',
  navigateState: set(curatedNavigateState, 'subscription.box', ({
    addonsPrice: 0,
    basePrice: 14900,
    name: 'Custom Classic',
    offersPrice: 0,
    size: 'classic',
    type: 'cst',
    addons: [],
    items: [
      {
        amount: 0,
        description: 'Burgers',
        fullDescription:
          'Our burgers are individually packed with a ratio of 80% meat to 20% fat&mdash;the perfect centerpiece of your next cookout.',
        image: 'https://www.butcherbox.com/apiv2/files/products/IQF_burgers_1',
        meatGroup: 'beef',
        packSize: '6 x 5.3 oz burgers',
        price: 0,
        quantity: 4,
        sku: '971313',
      },
      {
        amount: 0,
        description: 'Ground Beef (85/15) ',
        fullDescription:
          'This finely ground beef has a meat-to-fat ratio of 85% to 15%. Saut&eacute; for a quick weeknight dinner, or make into burgers and grill.',
        image:
          'https://www.butcherbox.com/apiv2/files/products/Ground-Beef_new1.jpg',
        meatGroup: 'beef',
        packSize: '2 x 1 lb packs',
        price: 0,
        quantity: 2,
        sku: '971314',
      },
    ],
    offers: [
      {
        amount: 0,
        description: 'Free Ground Beef For Life of Membership',
        fullDescription:
          'This finely ground beef has a meat-to-fat ratio of 85% to 15%. Saut&eacute; for a quick weeknight dinner, or make into burgers and grill.',
        image:
          'https://www.butcherbox.com/apiv2/files/products/Ground-Beef_new1.jpg',
        isPausable: true,
        meatGroup: 'beef',
        offerGroup: 'ground_beef_for_life',
        packSize: '2 x 1 lb packs',
        price: 0,
        quantity: 1,
        sku: '971314',
        status: 'active',
        groupedItems: [
          {
            amount: 0,
            description: 'Free Ground Beef For Life of Membership',
            fullDescription:
              'This finely ground beef has a meat-to-fat ratio of 85% to 15%. Saut&eacute; for a quick weeknight dinner, or make into burgers and grill.',
            image:
              'https://www.butcherbox.com/apiv2/files/products/Ground-Beef_new1.jpg',
            meatGroup: 'beef',
            packSize: '2 x 1 lb packs',
            price: 0,
            quantity: 1,
            sku: '971314',
          },
        ],
      },
    ],
  } as unknown) as schemata.Box),
}

export const simulateOneOrder: Simulation = {
  cacheKeys: ['orders?limit=2'],
  name:
    'Orders: Simulate only one order in order history (i.e., simulate first box)',
  responseInterceptor: () => (response: AxiosResponse) => {
    if (response.config.url.includes('orders?limit=2')) {
      response.data.data = [response.data.data[0]]
    }
    return response
  },
}

export const upcomingOrderIsCustomBox: Simulation = {
  name: 'Upcoming Order, Subscription: Set box type to custom',
  cacheKeys: [
    constants.CACHE_KEY_UPCOMING_ORDER,
    constants.CACHE_KEY_SUBSCRIPTION,
  ],
  responseInterceptor: (user, subscription) => (response: AxiosResponse) => {
    if (response.config.url === getUpcomingOrder(user.id, subscription.id)) {
      response.data.subscription.box.type = 'cst'
    }
    if (response.config.url === getSubscriptions(user.id)) {
      response.data.data[0].box.type = 'cst'
    }
    return response
  },
}

export const upcomingOrderIsCurated: Simulation = {
  name:
    'Upcoming Order, Subscription: Set box type to curated (Beef, Chicken, Pork)',
  cacheKeys: [
    constants.CACHE_KEY_UPCOMING_ORDER,
    constants.CACHE_KEY_SUBSCRIPTION,
  ],
  responseInterceptor: (user, subscription) => (response: AxiosResponse) => {
    if (response.config.url === getUpcomingOrder(user.id, subscription.id)) {
      response.data.subscription.box.type = 'beef_chicken_pork'
    }
    if (response.config.url === getSubscriptions(user.id)) {
      response.data.data[0].box.type = 'beef_chicken_pork'
    }
    return response
  },
}

export const simulateBillingDay: Simulation = {
  name:
    'Subscription, Upcoming Order: Set period end date (bill date) to X days from now',
  userPrompt: 'How many days?',
  cacheKeys: [
    constants.CACHE_KEY_UPCOMING_ORDER,
    constants.CACHE_KEY_SUBSCRIPTION,
  ],
  responseInterceptor: (user, subscription, daysFromNowStr) => (
    response: AxiosResponse
  ) => {
    const daysFromNow = Number(daysFromNowStr)
    if (!daysFromNow) return response
    const periodEndDate = dayjs().add(daysFromNow, 'd').format('YYYY-MM-DD')

    if (response.config.url === getUpcomingOrder(user.id, subscription.id)) {
      response.data.subscription.periodEndDate = periodEndDate
    }
    if (
      response.config.url === getSubscriptions(user.id) &&
      'periodEndDate' in response.data.data[0]
    ) {
      response.data.data[0].periodEndDate = periodEndDate
    }

    return response
  },
}

export const simulateBillingDayAndShowResubModal: Simulation = {
  ...simulateBillingDay,
  name: simulateBillingDay.name + ' - and show Resubscribe modal',
  redirectTo: '/member?resubscribed',
}

export const hamIsInWhatsForDinnerList: Simulation = {
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name: 'Orders: Add Uncured Boneless Ham to box items',
  redirectTo: '/account/whats-for-dinner',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const [order] = response.data.data

      order.box.items.push({
        amount: 0,
        creditAmount: 0,
        description: 'Boneless Uncured Ham',
        fullDescription: 'Boneless Uncured Ham',
        image:
          'https://www.butcherbox.com/wp-content/uploads/2015/07/DIC_mini_ham_3off.jpg',
        meatGroup: 'pork',
        packSize: '3lbs',
        price: 0,
        quantity: 1,
        // sku has no recipe and is included in justcook tags data
        sku: '458305',
      })
    }

    return response
  },
}

export const addRandomBadgesToDeals: Simulation = {
  cacheKeys: [
    constants.CACHE_KEY_MEMBER_DEALS,
    constants.CACHE_KEY_MEMBER_DEAL_LANDER,
    constants.CACHE_KEY_CHECKOUT_DEALS,
  ],
  name: 'Deals: Add random badges to deals cards',
  responseInterceptor: (user) => (
    response: AxiosResponse<{ data: schemata.Deal[] | schemata.LanderDeal[] }>
  ) => {
    if (!response) return

    if (
      [endpoints.GET_CHECKOUT_DEALS, endpoints.GET_DEALS(user?.id)].includes(
        response.config.url
      )
    ) {
      response.data.data = response.data.data.map((deal) => {
        const validBadges: BadgeType[] = [
          '$ off',
          '% off',
          'Best Value',
          'Bundle & Save',
          'Great Deal',
        ]
        const idx = Math.floor(Math.random() * validBadges.length)

        deal.badge = validBadges[idx]

        const strikeThroughPriceOffset = Math.floor(Math.random() * 5) * 100
        deal.strikethroughPrice = deal.price + strikeThroughPriceOffset

        return deal
      })

      return response
    }
  },
}

export const addSkusAndTagIdsToOrder: Simulation = {
  userPrompt: 'Enter a comma separated list of skus',
  cacheKeys: [`${constants.CACHE_KEY_ORDERS}?limit=2`],
  name: 'Orders: Add skus to box items and redirect to WFD',
  responseInterceptor: (user, _, skusString) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (!skusString) return response
    const skusList = skusString.split(',').map((sku) => Number(sku))
    const items = JustCookSkuAndTags.filter((item) =>
      skusList.includes(item.sku)
    )
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}?limit=2`) {
      const [order] = response.data.data

      items.forEach((item) => {
        order.box.items.push({
          amount: 0,
          creditAmount: 0,
          description: item.description,
          fullDescription: item.description,
          // this is just a placeholder image
          image:
            'https://butcherbox.dev/static/def683353f46b72d0897f4aef7507520/176df/how-it-works-grid.webp',
          meatGroup: item.meatGroup,
          packSize: '1lb',
          price: 0,
          quantity: 1,
          sku: `${item.sku}`,
        })
      })

      return response
    }
  },
  redirectTo: '/account/whats-for-dinner',
}

export const simulateOneTimeBoxInOrders: Simulation = {
  cacheKeys: ['orders?limit=2'],
  name: 'Orders: Simulate a one time box in the orders list',
  responseInterceptor: () => (response: AxiosResponse) => {
    if (response.config.url.includes('orders?limit=2')) {
      response.data.data[0].type = 'one_time'
    }
    return response
  },
}

export const excludeItemsFromOrders: Simulation = {
  cacheKeys: ['orders'],
  name: 'Orders: Remove items of SKU or slug from order',
  userPrompt: 'Enter the sku or recipe slug to exclude.',
  redirectTo: '/account/welcome-guide',
  responseInterceptor: (user, subscription, skuOrSlug) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}`) {
      response.data.data.forEach((order) => {
        order.box.items = order.box.items.filter((item) => {
          return Number(skuOrSlug)
            ? item.sku !== skuOrSlug
            : getTagInfoForSku(Number(item.sku))?.slug !== skuOrSlug
        })
      })
    }
    return response
  },
}

export const addBoxItemBySku: Simulation = {
  cacheKeys: ['orders'],
  name: 'Orders: Add item to each box & redirect to Welcome Guide',
  userPrompt: "Enter the sku for the item you'd like to add.",
  redirectTo: '/account/welcome-guide',
  responseInterceptor: (user, subscription, sku) => (
    response: AxiosResponse<{ data: schemata.Order[] }>
  ) => {
    if (response.config.url === `${endpoints.GET_ORDERS(user.id)}`) {
      response.data.data.forEach((order) => {
        if (!order.box.items.length) return response

        order.box.items.push({
          ...order.box.items[0],
          sku,
          description: `(fake) ${getTagInfoForSku(Number(sku))?.description}`,
          fullDescription:
            'This is a fake item, made from copying another item and changing the sku and description.' +
            " This simulation assumes that the SKU is all that's needed to achieve your desired effect from the" +
            ' simulation,',
        })
      })
    }
    return response
  },
}
